<template>
  <div class="merge" v-loading="loading">
    <div>
      <!--title-->
      <div class="title">
        <div class="btnp">
          <!-- 合并我的 -->
          {{ $t("message.duplication.label.mergeTitle") }} {{ obj.objlabel }}
        </div>
        <div>
          <i class="el-icon-close" @click="close"></i>
        </div>
      </div>
      <!--合并取消按钮-->
      <div style="padding-top: 20px">
        <div
          style="
            color: rgba(80, 116, 148, 1);
            font-size: 16px;
            padding-left: 24px;
          "
        >
          <!-- 选择要保留的值 -->
          {{ $t("message.duplication.label.sectionTitle") }}
        </div>
        <div
          style="
            width: 100%;
            text-align: center;
            padding-top: 52px;
            padding-bottom: 12px;
            border-bottom: 1px solid rgba(240, 240, 240, 1);
          "
        >
          <el-row>
            <el-button plain size="mini" @click="combination">{{
              $t("label.checkfield.merge")
            }}</el-button>
            <el-button plain size="mini" @click="close">{{
              $t("label.cancel")
            }}</el-button>
          </el-row>
        </div>
      </div>
      <div style="color: rgba(80, 116, 148, 1); padding-left: 24px">
        <div style="padding-top: 20px; white-space: nowrap">
          <!-- 请选择要在合并后的记录中保留的值。高标亮显示的行表明字段中包含冲突的数据。所选的主记录将保留只读和隐藏字段值 -->
          {{ $t("message.duplication.label.sectionremar1") }}
        </div>
        <div
          style="padding-top: 18px; padding-bottom: 20px; white-space: nowrap"
        >
          <!-- 注意：所有相关记录包括的任何备注、附件和活动都将与新的合并记录相关联 -->
          {{ $t("message.duplication.label.sectionremar2") }}
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div style="padding: 0px 24px 20px 24px">
      <table>
        <tr v-for="(item, index) in headLabel" :key="index">
          <!-- 首列 -->

          <td v-if="item.schemefieldName == 'name'"></td>
          <td
            :style="{ background: item.showInput ? '#D9EFFF' : '#F6F6F6' }"
            style="text-align: right; width: 240px"
            v-else-if="item.schemefieldName == 'id'"
          >
            <!-- 主记录 -->
            {{ $t("label.mr.mainRecord") }}
          </td>
          <td
            :style="{ background: item.showInput ? '#D9EFFF' : '#F6F6F6' }"
            style="text-align: right; width: 240px"
            v-else
          >
            {{ item.labelName }}
          </td>
          <!-- 循环生成的数据 -->
          <!-- 其他列 -->
          <td v-for="(Sitem, Sindex) in listObj" :key="Sindex">
            <input
              :data-index="Sindex"
              :value="Sitem[item.schemefieldName]"
              ref="inputRadio"
              v-if="item.showInput"
              :checked="Sindex == colSelectIndex"
              style="margin-right: 5px"
              type="radio"
              :name="item.schemefieldName"
            />
            <span v-if="item.schemefieldName == 'id'">
              {{ $t("label-combination-record", { index: Sindex + 1 }) }}
            </span>
             <!-- 文件 -->
             <span v-else-if="item.schemefieldType === 'FL'">
              <span
                v-for="file in Sitem[item.schemefieldName + 'List']"
                :key="file.fileid"
              >
                {{ file.filename }}
              </span>
            </span>
            <span v-else>
              {{
                item.schemefieldType == "Y" || item.schemefieldType == "R"
                  ? `${Sitem[item.schemefieldName + "ccname"]}`
                  : Sitem[item.schemefieldName]
              }}
            </span>
            <span @click="choseAll(Sindex)" style="padding-left: 5px">{{
              [item.schemefieldName] == "name"
                ? $t("message.duplication.label.allselectlink")
                : ""
            }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>


<script>
import { getOneGroupDataDetail, toMergeReData } from "./api.js";
export default {
  name: "merge",
  data() {
    return {
      // 存储列信息
      headLabel: [],
      // 存储记录详情
      listObj: [],
      // 保存当前列选中
      colSelectIndex: 0,
      //存储合并信息的jsonstr
      jsonstr: [],
      //保存所有被选中的input
      allSelectedInput: [],
      //保存所有的input
      allInput: [],
      loading: false,
      obj: this.$route.query,
      dataObject: {},
    };
  },
  created() {},
  mounted() {
    this.loading = true;
    this.Merge();
    // 将选中的id提取出来
    this.dataObject = JSON.parse(sessionStorage.getItem("cnkiParameter"));
  },
  methods: {
    
    goBack() {
      this.$router.go(-1);
    },
    Merge() {
      getOneGroupDataDetail(sessionStorage.getItem("cnkiParameter"))
        .then((res) => {
          this.loading = false;
          let result = res.data.fieldName_l.filter((item, i) => {
            if (item.schemefieldName == "name") {
              res.data.fieldName_l.splice(i, 1);
              return item;
            }
          });
          let result1 = res.data.fieldName_l.filter((item, i) => {
            if (item.schemefieldName == "id") {
              res.data.fieldName_l.splice(i, 1);
              return item;
            }
          });
          res.data.fieldName_l.unshift(result1[0]);
          res.data.fieldName_l.unshift(result[0]);
          //双重for循环遍历res.data

          for (let key in res.data.fieldName_l) {
            //外层循环 控制行数 遍历当前行数据
            var colName = res.data.fieldName_l[key].schemefieldName;
            var colResult = [];
            for (var index in res.data.reDataDetail_l) {
              colResult.push(res.data.reDataDetail_l[index][colName]);
            }
            var arr = [];
            for (var newKey in colResult) {
              if (arr.indexOf(colResult[newKey]) == -1) {
                arr.push(colResult[newKey]);
              }
            }
            //数据取值全部相同 给当前列  加一个不显示按钮的属性
            if (arr.length == 1) {
              res.data.fieldName_l[key].showInput = false;
            } else {
              res.data.fieldName_l[key].showInput = true;
            }
          }
          res.data.fieldName_l[0].showInput = false;
          this.headLabel = res.data.fieldName_l;
          this.listObj = res.data.reDataDetail_l;
        })
        .catch(() => {});
    },
    close() {
      this.$router.go(-2);
    },
    choseAll(index) {
      this.colSelectIndex = index;
    },
    combination() {
      this.loading = true;
      this.allInput = this.$refs.inputRadio;
      this.allSelectedInput = this.allInput.filter((item) => {
        return item.checked == true;
      });
      let object = {};
      // 选中的主记录id  对应的name
      let id = this.allSelectedInput[0].value;
      //   从数据列表中筛选出与主记录id匹配的数据
      let array = this.listObj.filter((item) => {
        if (item.id == id) {
          return item;
        }
      });
      //   准备name的取值
      object.name = array[0].name;
      //   准备id的取值
      object.id = id;
      //   准备有单选框的列名称
      let hasRadio = this.headLabel.filter((item) => {
        return item.showInput == true && item.schemefieldName !== "id";
      });
      hasRadio.map((item) => {
        object[item.schemefieldName] = "";
      });
      //   准备单选框的取值
      for (var key in object) {
        this.allSelectedInput.map((item) => {
          if (item.name == key) {
            object[key] = item.value;
          }
        });
      }
      //   准备没有单选框的列名称
      let noRadio = this.headLabel.filter((item) => {
        return item.showInput == false;
      });
      noRadio.map((item) => {
        object[item.schemefieldName] = "";
      });
      //   准备没单选框的取值
      for (let key in object) {
        if (object[key] == "") {
          object[key] = this.listObj[0][key];
        }
      }
      for (let key in object) {
        if (!object[key]) {
          object[key] = "";
        }
      }
      let str = JSON.stringify(object);
      this.dataObject.jsonStr = str;
      let dataObject = JSON.stringify(this.dataObject);
      this.postCombinationResult(dataObject);
    },
    //调用合并记录接口
    async postCombinationResult(obj) {
      let result = await toMergeReData(obj);
      if (!result.result) {
        this.$message.error(result.returnInfo);
      } else {
        this.$message.success(this.$i18n.t("label.mr.mrSuccess"));
        this.$router.go(-2);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.merge {
  overflow-x: auto;
  background: #ffffff;
  border-radius: 4px;
  margin: 10px;
  margin-right: 0px;
}

.title {
  display: flex;
  justify-content: space-between;
  padding: 21px 19px 16px 24px;
  border-bottom: 3px solid rgba(240, 240, 240, 1);
}
table {
  border-collapse: collapse;
  width: 100%;
}
tr {
  height: 30px;
}
td {
  border: 1px solid #dddbda;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 14px;
  padding-right: 14px;
  height: 30px;
}
/* 表格一行一列背景色 */
tr:first-child {
  background-color: #f6f6f6;
}
/* tr>td:first-child{
  background-color: #F6F6F6;
} */
</style>